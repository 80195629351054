.Repports {
  min-width: 960px;
  margin: 1% 1.5%;
  font: 12px "OpenSans", sans-serif;
}

.select_facture_custom {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url('data:image/svg+xml;utf8,<svg fill="hsl(0,0%,80%)" width="20" height="20" xmlns="http://www.w3.org/2000/svg" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down" role="img" viewBox="0 0 320 512"><path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 99.2%;
  background-position-y: 8px;
  padding: 8px;
}

.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 8px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #7189a8;
  border: 2px solid #d9e3e8;
}

.facture_patient_title {
  font-size: 14px;
  font-weight: 700;
  color: #494949 !important;
  margin-bottom: 10px;
}

.input_factur_group {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input_date_rapport {
  display: flex;
  justify-content: space-between;
}

.statistiques {
  display: flex;
  justify-content: space-between;
  margin: 1% 0%;
}

.statistic {
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 19%;
  text-align: center;
  padding: 16px 0px;
}
.statistic p {
  font-family: "openSans-Bold";
  color: #494949;
  font-size: 14px;
}
.statistic p:first-child {
  font-family: "openSans";
  color: #494949;
  font-size: 14px;
}
