.table_pagination {
  border-top: 1px solid #8d8d8d;
  word-wrap: "break-word";
  font-weight: 600;
  padding: 6px 5px;
}
.pagination_tr {
  cursor: pointer;
}
.pagination_tr:hover {
  background-color: none !important;
}
