@mixin centerItems {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-9 {
  width: 100%;
  font-family: $font;
  height: fit-content;
  position: relative;
  background-color: $secondry-color;
  margin: 0 auto;
  z-index: 900;

  .container {
    width: 100%;
    height: fit-content;
    margin: 0 auto;
    position: relative;
    padding-top: 40px;
    @include media("<=1200px") {
      max-width: 90%;
    }

    @include media("<=991px") {
      padding-top: 50px;
    }

    @include media("<=500px") {
      max-width: 100%;
    }

    .h1 {
      color: white !important;
      text-align: center;
    }
    .p {
      text-align: center;
      color: white !important;
      margin: 30px 0 50px;
    }

    .infos {
      height: fit-content;
      @include flexb(row, space-evenly, center);

      .info {
        @include flexb(row, flex-start, center);
        .icon {
          margin-right: 15px;
          width: 90px;
          height: 90px;
          background-color: white;
          float: left;
          border-radius: 100px;
          box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
          @include centerItems();
        }

        @include media("<=500px") {
          .icon {
            width: 50px;
            height: 50px;

            img {
              width: 24px;
              height: 24px;
            }
          }
        }

        .text {
          color: white;
          margin-top: 10px;
          height: fit-content;
          a {
            font-size: 1.8rem;
            font-weight: 500;
          }

          label {
            font-size: 20px;
            display: block;
            color: white !important;
            margin-bottom: 0.5em;
          }
        }
      }

      @include media("<=850px") {
        flex-direction: column;
        margin: 0 auto;

        .info {
          max-width: 400px;
          width: 100%;
          margin-bottom: 30px;
        }
      }

      @include media("<=500px") {
        .info {
          max-width: 250px;
          margin: 0 auto 30px;
          align-items: center;

          .text {
            a {
              font-size: 1rem;
            }

            label {
              font-size: 12px;
              color: white !important;
            }
          }
        }
      }
    }

    .form {
      width: 100%;
      height: fit-content;
      margin: 0 auto;
      background-color: white;
      border-radius: 32px;
      box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
      padding: 30px;
      transform: translateY(100px);

      @include media("<=991px") {
        width: 100%;
      }

      .group-col {
        @include flexb;
        flex-wrap: wrap;
        gap: 15px;
        margin-bottom: 20px;
      }

      .Item {
        width: 320px;
        margin-top: 19px;

        .btn-customize {
          background-color: $secondry-color;
          max-height: 71px;
          height: 71px !important;
          max-width: 450px;
          width: 100%;
          border-radius: 100px;
          margin-top: 30px;
          text-align: left !important;
          font-size: 16px;
          &:hover {
            cursor: pointer;
            background-color: $secondry-color-hover;
          }
        }
        @include media("<=991px") {
          .btn-customize {
          }
        }
      }

      @include media("<=500px") {
        padding: 3vw;

        .Item {
          margin-right: 0px;
        }
        .terms {
          @include flexb(column, center, flex-end);
        }
      }
    }

    .terms {
      @include flexb(row, center, flex-end);
      max-width: 930px;
      width: 100%;
      // margin: 30px auto;

      input {
        margin-top: 6px;
        margin-right: 20px;
      }

      .scales {
        transform: scale(1.2);
      }

      a {
        text-decoration: underline;
        font-weight: 400;
      }
    }
  }
}

.sobrus-email-footer {
  position: relative;
  img {
    position: absolute;
    right: -30px;
    top: -5px;

    &:hover {
      opacity: 0.7;
    }

    &:active,
    &:focus {
      opacity: 0.5;
    }
  }
}
