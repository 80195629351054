.OrdonnancesTable_container{
  min-width: 960px;
  font: 12px "OpenSans", sans-serif;

}
.dropzone {
  background-color: #dedede;
  border: 1px solid #dedede;
  padding: 3% 10%;
  border-radius: 3px;
}

.thumb {
  position: relative;
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-left: 8px;
  height: auto;
  padding: 2px;
  box-sizing: border-box;
}

.thumbInner {
  display: flex;
  min-width: 0px;
  overflow: hidden;
  position: absolute;
  top: -85px;
}

.thumbInner_img {
  display: block;
  width: auto;
  height: 70px;
  margin-right: 10px;
  max-width: 800px;
}
.filePrincToUpdate {
  position: relative;
}
.filePrincToUpdate_icon {
  position: absolute;
  top: 0;
  right: 8px;
  font-size: 15px;
  color: red;
}
