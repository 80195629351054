.CustomSelect {
  min-width: 150px;
  width: 100%;
  height: 100%;
  font: 12px "OpenSans", sans-serif;

  
}
.Custom_select_input_container {
  width: 100%;
  height: 100%;
  position: relative;
}
.updateSelect-erreur {
  width: 100%;
  height: 100%;
  resize: none;
  margin: 0;
  outline: none;
  font-size: 14px;
  padding-left: 10px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-color: #ffffff;
  border-radius: 6px;
  border: solid 1px #ff068b;
}
.updateSelect {
  width: 100%;
  height: 100%;
  resize: none;
  margin: 0;
  outline: none;
  font-size: 14px;
  padding-left: 10px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-color: #ffffff;
  border-radius: 6px;
  border: solid 1px #bbc8d3;
  font-family: "OpenSans";
}
.updateSelect:hover {
  border: solid 1px #18b1d4;
}
.updateSelect:focus-within,
.updateSelect:valid {
  border: solid 1px white;
}
.updateSelect:focus ~ label,
.updateSelect:valid ~ label {
  background-color: #fff;
  top: 33%;
  width: auto;
  left: 90%;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 11px;
  color: #18b1d4;
  transition: all 0.1s ease;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.updateSelect:not(:focus) ~ label,
.updateSelect:not(:valid) ~ label {
  transition: all 0.1s ease;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.Custom_select_lable {
  position: absolute;
  left: 2%;
  top: 34%;
  font-family: Roboto;
  font-size: 14px;
  line-height: 0.94;
  letter-spacing: 0.22px;
  text-align: left;
  color: #284358;
}
.Custom_select_lable_erreur {
  position: absolute;
  left: 2%;
  top: 34%;
  font-family: Roboto;
  font-size: 14px;
  line-height: 0.94;
  letter-spacing: 0.22px;
  text-align: left;
  color: #ff068b;
}
.Custom_select_icon {
  position: absolute;
  right: 2%;
  top: 29%;
}
.Custom_select_error_text {
  display: inline-block;
  color: #ff068b;
  font-size: 10px;
  vertical-align: top;
}
