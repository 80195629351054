@mixin centerItems {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing_page_header {
  width: 100%;
  margin: 0 auto;
  padding: 30px 0px;
  font-family: $font;
  z-index: 1000;
  height: fit-content;
  position: absolute;
  top: 45px;
  font-family: $font;

  /* MOBILE */
  @include media("<=phone") {
    width: 100%;
  }
  /* END MOBILE */

  /* TABELT */
  @include media(">phone", "<=tablet") {
    width: 100%;
    margin: 0 auto;
    display: block;
  } /* END TABELT */

  @media screen and (min-width: 1400px) {
    .temp {
      max-width: 1250px !important;
    }
  }

  .container {
    width: 100%;
    margin: 0 auto;

    @include flexb(row, space-between);

    /* MOBILE */
    @include media("<=phone") {
      width: 100%;
      margin: 0 auto;
      display: block;
    } /* END MOBILE */

    /* TABELT */
    @include media(">phone", "<=tablet") {
      width: 100%;
      margin: 0 auto;
      display: block;
    } /* END TABELT */

    /* TABELT */
    @include media(">tablet", "<=desktop") {
      width: 100%;
      margin: 0 auto;
      display: block;
    } /* END TABELT */
  }

  .logo {
    /* MOBILE */
    width: 155px;
    @include media("<=phone") {
      width: 155px;
    }
    /* END MOBILE */
  }

  .btnMenu,
  .menuModal {
    display: none;
  }

  .hidden {
    display: none;
  }

  nav {
    width: 70% !important;
    @include flexb(row, space-between);
    /* MOBILE */
    @include media("<=phone") {
      display: none;
    }
    /* END MOBILE */

    /* TABELT */
    @include media(">phone", "<=tablet") {
      display: none;
    } /* END TABELT */

    @include media("<=1400px") {
      width: 75% !important;
    }

    @include media("<=1200px") {
      width: 79% !important;
    }

    @include media("<=desktop") {
      display: none;
    }

    a {
      text-decoration: none;
      text-align: center;
      font-size: 14px;
      color: $font-color;
      cursor: pointer;
      padding: 0px;
    }
    a:hover {
      color: $primary-color;
    }

    @include media("<=1200px") {
      a {
        font-size: 12px;
        margin-right: 5px;
      }
    }

    .is-active {
      position: relative;
      font-weight: bold;
      color: $primary-color;
      padding-bottom: 4px;
      border-bottom: 3px solid $primary-color;
      span {
        width: 10px;
        height: 10px;
        margin: 0 auto;
        background-color: $primary-color;
        position: absolute;
        top: -15px;
        left: 45%;
        border-radius: 100px;
      }
    }

    .underline {
      border-bottom: 3px solid $primary-color;
    }

    div {
      @include flexb();
    }
  }

  /* MOBILE */
  @include media("<=phone") {
    .btnMenu {
      width: 50px;
      height: 50px;
      border-radius: 8px;
      background-color: $primary-color-opacity;
      @include centerItems();
      float: right;
      margin-right: 10px;
    }

    .btnClose {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      background-color: $primary-color-opacity;
      text-align: center;
      @include centerItems();
      margin-right: 10px;
    }

    .menuModal {
      display: block;
      width: 100%;
      height: 100%;
      position: fixed;
      background-color: white;
      @include centerItems();
      opacity: 0.9;
      ul {
        li {
          list-style: none;
          font-family: $font;
          padding: 10px;
          color: $font-color;
          font-size: 18px;
          text-align: center;
          @include centerItems();
          &:hover {
            color: $primary-color;
          }
        }
      }

      .is-active {
        position: relative;
        font-weight: bold;
        color: $primary-color;
        border-bottom: 3px solid $primary-color;
        span {
          width: 10px;
          height: 10px;
          margin: 0 auto;
          background-color: $primary-color;
          position: absolute;
          top: -15px;
          left: 45%;
          border-radius: 100px;
        }
      }
    }
  }
  /* END MOBILE */

  /* TABELT */
  @include media(">phone", "<=tablet") {
    .btnMenu {
      width: 50px;
      height: 50px;
      border-radius: 8px;
      background-color: $primary-color-opacity;
      @include centerItems();
      float: right;
      margin-right: 10px;
    }

    .btnClose {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      background-color: $primary-color-opacity;
      text-align: center;
      @include centerItems();
      margin-right: 10px;
    }

    .menuModal {
      display: block;
      width: 100%;
      height: 100%;
      position: fixed;
      background-color: white;
      @include centerItems();
      opacity: 0.9;
      ul {
        li {
          list-style: none;
          font-family: $font;
          padding: 10px;
          color: $font-color;
          font-size: 18px;
          text-align: center;
          @include centerItems();
          &:hover {
            color: $primary-color;
          }
        }
      }

      .is-active {
        position: relative;
        font-weight: bold;
        color: $primary-color;
        border-bottom: 3px solid $primary-color;
        span {
          width: 10px;
          height: 10px;
          margin: 0 auto;
          background-color: $primary-color;
          position: absolute;
          top: -15px;
          left: 45%;
          border-radius: 100px;
        }
      }
    }
  } /* END TABELT */

  /* TABELT */
  @include media("<=desktop") {
    .btnMenu {
      width: 50px;
      height: 50px;
      border-radius: 8px;
      background-color: $primary-color-opacity;
      @include centerItems();
      float: right;
      margin-right: 10px;
    }

    .btnClose {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      background-color: $primary-color-opacity;
      text-align: center;
      @include centerItems();
      margin: 10px auto;
    }

    .menuModal {
      display: block;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-color: white;
      @include centerItems();
      opacity: 0.9;
      ul {
        padding: 0;
        li {
          list-style: none;
          font-family: $font;
          color: $font-color;
          font-size: 18px;
          text-align: center;
          padding: 10px;
          @include centerItems();
          &:hover {
            color: $primary-color;
          }
        }
      }

      .is-active {
        position: relative;
        font-weight: bold;
        color: $primary-color;
        border-bottom: 3px solid $primary-color;
        span {
          width: 10px;
          height: 10px;
          margin: 0 auto;
          background-color: $primary-color;
          position: absolute;
          top: -15px;
          left: 45%;
          border-radius: 100px;
        }
      }
    }
  } /* END TABELT */
}

.fixed-nav {
  position: fixed;
  top: 0;
  background-color: white;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
  padding: 15px 0px;

  animation: 1s opa;
}

@keyframes opa {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
