@mixin centerItems {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-4 {
  font-family: $font;
  height: auto;
  margin: 0 auto;
  background-color: $primary-color;
  padding: 50px;

  @include media("<=991px") {
    padding: 30px 0px;
    height: auto;
  }

  .container {
    margin: 0 auto;
    width: 100%;
    @include centerItems();
    .content-1 {
      @include centerItems();
    }
    .content-2 {
      @include centerItems();
    }
    .rightSide {
      .btn-custom {
        @include flexb(row, space-evenly, center);
        gap: 10px;
        max-width: 400px;
        width: 100%;
        height: 60px;
        position: relative;
        background-color: white !important;
        color: black !important;
        font-size: 20px;

        img {
          margin-top: 3px;
        }

        @include media("<=desktop") {
          margin-top: 20px;
        }

        @include media("<=500px") {
          font-size: 16px;
        }

        &:hover {
          background-color: #b3b2b2 !important;
          cursor: pointer;
        }
      }
    }
  }
}
