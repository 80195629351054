.top_options_btns {
  display: flex;
  font: 12px "OpenSans", sans-serif;

}
.top_options_wrapper {
  position: sticky !important;
  top: 0;
  z-index: 1;
  font: 12px "OpenSans", sans-serif;

}
.Top_options_title {
  font-size: 16px !important;
  font-weight: 700 !important;
  font: 12px "OpenSans", sans-serif;

}
