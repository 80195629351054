.section-3 {
    font-family: $font;
  background-color: #1ec28b17;
  margin-top: 100px;
  height: fit-content;
  padding: 50px 0;
  width: 100%;
  position: relative;

  .section-3-title {
      margin: 50px 0 0 100px;
  }

  .line {
      height: 100%;
      width: 100%;
      margin-bottom: 50px;
      overflow: hidden;
      @include flexb;

      .line-img {
          object-fit: contain;
          max-width: 100vw;
      }

      .line-mobile-img {
          display: none;
      }
  }

  @include media("<=1000px") {
      padding-bottom: 0;

      .section-3-title {
          margin: 5vw 0 0 8vw;
      }

      .line {
          margin-bottom: 0;
          .line-img {
              display: none;
          }

          display: flex;
          justify-content: center;
          align-items: flex-start;

          .line-mobile-img {
              display: flex;
          }
      }
  }

  @include media("<=450px") {
      .line {
          .line-mobile-img {
              width: 100%;
          }
      }
  }

  @include media("<=350px") {
      .section-3-title {
          .h3 {
              font-size: 16px;
          }

          .h1 {
              font-size: 18px;
          }
      }
  }
}
