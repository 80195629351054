.pageHeader-wrapper {
  background-color: $primary-color;
  margin-top: 105px;
  padding: 45px 20px;
  color: white;
  font-family: $font !important;

  .container {
    margin-top: 0 !important;
    font-family: $font;
    div {
      @include flexb(row, flex-start);
      a {
        font-family: $font !important;
      }
      p {
        font-family: $font;
        margin: 0;
        font-weight: 600;
        color: white;
      }
    }

    .h1 {
      margin-top: 20px;
      color: white;
    }
  }

  @include media("<=tablet") {
    padding: 45px 10px;
    .h1 {
      font-size: 1.9rem;
    }
  }

  @include media("<=phone") {
    font-size: 0.8rem;

    .h1 {
      font-size: 1.5rem;
    }
  }
}
