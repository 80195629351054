.NotFoundPage {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #58a596;
  flex-direction: column;
}
.notFoundButton {
  background: #fff;
  padding: 12px 54px;
  border-radius: 10px;
  color: #58a596;
  font-weight: 800;
  font-size: 12px;
  border: 1px solid #fff
}
.notFoundButton:hover {
  background: #58a596;
  padding: 12px 54px;
  border-radius: 10px;
  color: #ffffff;
  font-weight: 800;
  font-size: 12px;
  border: 1px solid #fff
}

.NotFoundTitle {
  font-size: 24px;
  font-weight: bold;
  font-family: "OpenSans";
  color: #fff;
  display: inline-block;
  vertical-align: top;
  margin-top: 7px;
  margin-bottom: 13px;
  text-align: center;
}
