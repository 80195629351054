.article-wrapper {
  padding: 50px 20px;
  // background-color: #fff;
  font-family: $font;

  .container {
    .h4 {
      margin-bottom: 30px;
    }

    p {
      font-size: 1rem;
      font-weight: 400;
    }

    ol {
      padding-left: 25px;
      li {
        line-height: 1.25em;
      }
    }

    @include media("<=350px") {
      p,
      ol {
        font-size: 0.8rem;
      }
    }
  }
}

.bg {
  background-color: #fff;
}

.conditions {
  font-family: $font !important;
  .container {
    margin-top: 50px;
    .h4 {
      margin-bottom: 50px;
    }

    .address {
      @include flexb(row, space-between, flex-start);
      flex-wrap: wrap;
      .address-right,
      .address-left {
        font-family: $font !important;
        p {
          margin-top: 0;
          margin-bottom: 1rem;
        }
        h5 {
          font-size: 1.25rem;
          margin-top: 0;
          margin-bottom: 0.5rem;
          font-weight: 500;
          line-height: 1.2;
        }
      }
    }
  }
}
