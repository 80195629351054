@font-face {
  font-family: "RobotoSlab-Bold";
  src: url("./Fonts/RobotoSlab-Bold.ttf");
}
@font-face {
  font-family: "openSans";
  src: url("./Fonts/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "openSans-Bold";
  src: url("./Fonts/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "gotham";
  src: url("./Fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "Raleway-Black";
  src: url("./Fonts/Raleway-Black.ttf");
}
@font-face {
  font-family: "Raleway-Light";
  src: url("./Fonts/Raleway-Light.ttf");
}
@font-face {
  font-family: "Raleway-Regular";
  src: url("./Fonts/Raleway-Regular.ttf");
}
@font-face {
  font-family: "gotham-Bold";
  src: url("./Fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "openSans-Light";
  src: url("./Fonts/OpenSans-Light.ttf");
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

div[class$="-singleValue"] {
  color: #7189a8 !important;
}

.ParametresOrdonnanceEdit {
  box-shadow: 0 1px 3px 0 #bcbcbc;
}
.sign_up_notification {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cdeaca;
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.sign_up_notification i {
  margin-right: 10px;
  font-size: 25px;
  color: #3a7824;
}

.sign_up_notification p {
  color: #3a7824;
  font-family: "openSans-Light";
}

.LandingPage{
  background-color: #fff !important;
}
.pharmacistId_error {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fddcdc;
  font-size: 14px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.pharmacistId_error i {
  margin-right: 10px;
  font-size: 25px;
  color: #ff2b2b;
}

.pharmacistId_error p {
  color: #ff2b2b;
  font-family: "openSans-Light";
}
