.ParametresInfos {
  margin-bottom: 10px;
  box-shadow: 0 1px 3px 0 #bcbcbc;
  min-width: 960px;
  font: 12px "OpenSans", sans-serif;

}
.info_profile_and_sub_menu {
  margin: 1%;
  margin-left: 1.5%;
  display: flex;
  margin-right: 1.5%;
}
.profilInfoCard {
  width: 100%;
  box-shadow: 0 1px 3px 0 #bcbcbc;
  display: inline-block;
  vertical-align: top;
  /* padding-bottom: 10px; */
}
.profil_info_card_header {
  position: relative;
  padding: 10px;
  background: #f0f0f0;
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
}
.profil_info_card_header h3 {
  float: left;
}
.profil_info_card_header:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.profil_info_card_bloc_content {
  padding: 10px;
  background: #fff;
  position: relative;
}

.profil_info_card_bloc_content:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.profil_info_card_thumb {
  width: 111px;
  height: 111px;
  display: block;
  float: left;
  border-radius: 60px;
}
.profil_info_card_thumb img {
  width: 100%;
  height: 100%;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  border-radius: 60px;
}
.profil_info_card_cnt {
  margin-left: 131px;
}
.profil_info_card_row {
  margin-bottom: 15px;
  height: 33%;
}
.profil_info_card_row span {
  font-weight: 600;
  color: grey;
}
.profil_info_card_row p {
  font-size: 14px;
  font-weight: 700;
  color: #494949 !important;
}
.profil_info_card_left,
.profil_info_card_right {
  width: 50%;
  float: left;
  padding: 0 30px;
}
.GroupSanguinButton {
  width: 30%;
  background-color: #ce5c4f;
  font-family: "openSans";
  margin-top: 7px;
  box-shadow: 0 1px 3px 0 #bcbcbc;
}
.groupSanguin {
  font-family: "RobotoSlab-Bold";
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 15px;
  color: #fff;
}
.groupSanguin i {
  font-size: 15px;
  color: #993c30;
}
.SuggestionStatut {
  margin-top: 7px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  width: 80%;
}
.patientInfoCart {
  margin-top: 10px;
}
