.header-links {
    background-color: $primary-color;
    font-family: $font;
    .header-container {
        padding: 0 20px;
        margin: 0 auto;
        color: white;
        @include flexb(row, space-between);
        gap: 20px;
        height: 45px;

        @media screen and (min-width: 1400px) {
            max-width: 1250px !important;
        }

        p {
            color: white;
            font-size: 0.85rem;
            font-weight: 500;
            margin: 0;
        }

        .social-links {
            @include flexb;
            gap: 20px;
        }
    }
}
