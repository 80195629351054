.ChoiceCountries {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 40px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #ffffff;
  height: 100vh;
  min-width: 960px;
  font: 12px "OpenSans", sans-serif;

}
.ChoiceCountries_left {
  width: 50%;
  padding-left: 5%;
}
.ChoiceCountries_right {
  text-align: center;
  width: 50%;
  background: #27b9ac0f 0% 0% no-repeat padding-box;
  border-radius: 34px;
  padding-top: 100px;
  padding-bottom: 100px;
}
.ChoiceCountries_right_title {
  font-family: "Raleway-Light";
  font-size: 38px;
  color: #000000;
  margin-top: 40px;
}
.ChoiceCountries_right_title strong {
  font-family: "Raleway-Regular";
  font-size: 38px;
  color: #000000;
}
.ChoiceCountries_left_title {
  font-family: "Raleway-Regular";
  font-size: 48px;
  color: #000000;
  padding-top: 24px;
}
.ChoiceCountries_left_login_link {
  font-family: "Raleway-Regular";
  font-size: 20px;
  color: #27b9ac;
  cursor: pointer;
}
.pharmacy_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 32px #44444410;
  border: 1px solid #ededed;
  text-align: center;
  height: 300px;
  width: 275px;
  border-radius: 15px;
  margin-right: 40px;
  padding: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.pharmacy_card_name {
  font-family: "Raleway-Regular";
  font-size: 24px;
  color: #000000;
  margin-top: 24px;
}
.pharmacy_card_image_container {
  width: 100px;
  height: 100px;
  overflow: hidden;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000012;
  border-radius: 75px;
  text-align: center;
  margin: auto;
}
.pharmacy_cards_container {
  display: flex;
  padding-top: 34px;
  padding-bottom: 40px;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 80%;
}

.pharmacy_cards_container::-webkit-scrollbar {
  display: none;
}

.ChoiceCountries_image {
  height: 300px;
  width: 300px;
}
.container_with_arrow {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.accueil_sixth_part_icon {
  font-size: 40px;
  color: #ccccccb3;
  cursor: pointer;
}
.pharmacy_card_image {
  width: 30px;
  height: auto;
  padding-top: 24px;
}
