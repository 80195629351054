.Popup_header {
  height: 35px;
  line-height: 32px;
  font-size: 14px;
  font-weight: 600;
  padding: 7px 15px;
  background: #e5e5e5;
  border-top-right-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font: 12px "OpenSans", sans-serif;

}
.Popup_header p {
  font-family: "OpenSans";
  font-weight: 700;
  font-size: 14px;
  color: #494949;
}
.Popup_header i {
  font-size: 14px;
  cursor: pointer;
}
.PatientsPopup_footer {
  display: flex;
  padding: 10px;
}
.popup_close {
  margin-left: 5px;
}
.input_search {
  margin-bottom: 10px;
}
.PatientsPopup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.PatientsPopup\_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 35%;
  bottom: 25%;
  margin: auto;
  border-radius: 20px;
  background: white;
}
.table thead th {
  text-align: left;
  font-weight: 700 !important;
  padding: 10px 5px;
  background: #f8f8f8;
  position: relative;
}
.PatientsPopup_TR {
  height: 50px;
}
.PatientsPopup_TR td {
  font-weight: 400;
  padding: 10px 5px;
}
.input_search input:focus {
  outline: 1px solid #18b1d4 !important;
}
.PatientsPopup_TR {
  border: 1px solid #8d8d8d59;
}
