.InfoCard {
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 100%;
  position: relative;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  font: 12px "OpenSans", sans-serif;

}
.see_patient_info {
  padding: 3px;
  margin-right: 3px;
}
.download_pdf_patient {
  padding: 3px;
}
.table tbody tr:hover .see_patient_info {
  padding: 3px;
  background-color: #fff;
  color: #0a8fad;
  border-radius: 50%;
}
.table tbody tr:hover .download_pdf_patient {
  padding: 3px;
  background-color: #fff;
  color: #0a8fad;
  border-radius: 50%;
}
