.h1 {
  font-size: 3rem;
  font-weight: 500;
  font-family: $font;
  color: $font-color;
  line-height: 1.3;
}

.h2 {
  font-size: 2.2rem;
  font-weight: 300;
  font-family: $font;
  color: white;

  strong {
    font-weight: 500;
  }
}

.h3 {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: $font;
  color: $primary-color;
  line-height: 0.4;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.h4 {
  font-size: 2rem;
  font-weight: 500;
  font-family: $font;
}

.p {
  font-size: 20px;
  font-family: $font;
  color: $font-color;
  font-weight: 300;
  margin-bottom: 0.50em;
  line-height: 1.5em;
  strong {
    font-weight: 500;
  }
}

a {
  text-decoration: none;
  color: inherit;
  padding: 0px;
  &:hover {
    color: inherit;
  }
}

@include media("<=1200px") {
  .h1 {
    font-size: 2.5rem;
  }

  .h2 {
    font-size: 1.9rem;
  }

  .p {
    font-size: 18px;
  }
}

@include media("<=500px") {
  .h1 {
    font-size: 1.5rem;
  }

  .h2 {
    font-size: 1.2rem;
  }

  .h3 {
    font-size: 1.2rem;
  }

  .p {
    font-size: 16px;
  }
}

@include media("<=350px") {
  .p {
    font-size: 12px;
  }

  .h4 {
    font-size: 1.5rem;
  }
}
