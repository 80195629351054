.PharmacyService {
  min-width: 960px;
  font: 12px "OpenSans", sans-serif;
}
.ouiButton {
  background-color: #28b9ac;
  border: 1px solid #28b9ac;
  border-radius: 3px;
  padding: 3px 12px 3px 12px;
  color: #fff;
  text-align: center;
  font-family: "openSans-bold";
  display: inline-block;
}

.nonButton {
  background-color: #db544c;
  border: 1px solid #db544c;
  border-radius: 3px;
  padding: 3px 10px 3px 10px;
  color: #fff;
  text-align: center;
  font-family: "openSans-bold";
  font-family: "openSans-bold";
  display: inline-block;
}
