.AddsComponent {
  width: 24%;
  float: right;
  position: relative;
  margin-left: 0.5%;
  margin-right: 0.5%;
  font: 12px "OpenSans", sans-serif;
}
.banner {
  background-color: #f0f0f0;
  min-height: auto;
}
.banner a {
  outline: 0;
  text-decoration: none;
}
.banner img {
  max-width: 100%;
  border: none;
  margin: 0;
}
.bande_dashBord {
  width: 100%;
  display: flex;
  background-color: #fff;
  align-items: center;
  box-shadow: 0 1px 3px 0 #bcbcbc;
  padding: 15px;
  margin-bottom: 10px;
}
.paragraph_totalePosologies {
  font-family: "OpenSans";
  font-size: 14px;
  color: #494949;
}
.totalePosologies {
  font-family: "RobotoSlab-Bold";
  font-size: 26px;
  color: #f2a628;
}
.totalepatients {
  font-family: "RobotoSlab-Bold";
  font-size: 26px;
  color: #02c288;
}
.totaleProduits {
  font-family: "RobotoSlab-Bold";
  font-size: 26px;
  color: #3d7ea6;
}
.totalItemsWidth h2 {
  width: 100px;
}
.app_statistiques {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.app_visiteres {
  box-shadow: 0 1px 3px 0 #bcbcbc;
  background-color: #1ebdaf;
  width: 49%;
  padding: 10px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  position: relative;
}
.app_visiteres_favorites {
  box-shadow: 0 1px 3px 0 #bcbcbc;
  background-color: #fda82a;
  width: 49%;
  padding: 10px 12px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  position: relative;
  height: 71px;
}
.app_location {
  box-shadow: 0 1px 3px 0 #bcbcbc;
  background-color: #ff0047;
  width: 49%;
  padding: 10px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  position: relative;
  height: 71px;
}
.app_calls {
  box-shadow: 0 1px 3px 0 #bcbcbc;
  background-color: #37bef5;
  width: 49%;
  padding: 10px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  position: relative;
  height: 71px;
}
.app_visiteres_icon {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}
.app_visiteres_warning_icon {
  color: #fff;
  font-size: 17px;
  /* margin-top: 11%; */
  position: absolute;
  right: 5px;
  bottom: 5px;
  height: 71px;
}

.app_statistiques p {
  font-family: "openSans-Bold";
  font-size: 12px;
  color: #fff;
}
