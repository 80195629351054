.section-2 {
  font-family: $font;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;

  height: fit-content;

  .container {
    height: fit-content;
    margin-top: 250px;
    .p_lil {
      font-size: 0.9rem;
      font-weight: 300;
      color: #ed8e88;
      font-style: italic;
    }
    @include media("<=991px") {
      height: fit-content;
      margin-top: 50px;
      .content-s-2 {
        margin-top: 50px;
        
        @include media("<=500px") {
          margin-top: 20px;
        }
      }
    }
  }
}
