.Main_container {
  height: 100%;
  min-width: 960px;
  font: 12px "OpenSans", sans-serif;

}
.Content {
  margin-top: 10px;
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
}
.Post_container {
  width: 49%;
  display: inline-block;
  vertical-align: top;
  margin-left: 0.5%;
  margin-right: 0.5%;
  margin-bottom: 1%;
  min-height: auto;
}
.PostsLoader {
  width: 100%;
  display: block;
  position: relative;
  margin-left: 0.5%;
  margin-right: 0.5%;
  margin-top: 3%;
  background: #fff;
  box-shadow: 0 1px 3px 0 #bcbcbc;
  height: 48px;
  background: #fff;
}
.PostsLoader p {
  font-family: "openSans";
  font-size: 14px;
  color: #1ebeb0;
  text-align: center;
  padding-top: 13px;
  cursor: pointer;
}
