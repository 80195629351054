@mixin centerItems {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-8 {
  font-family: $font;
  background-color: $secondry-color-opacity;
  margin: 80px auto 0;
  padding: 4vw;
  @include media("<=desktop") {
    padding: 100px 2vw;
  }

  .container {
    height: auto;
    margin: 0 auto;

    @include media("<=tablet") {
      padding: 0;
    }

    .h3 {
      color: $secondry-color;
    }

    div {
      @include flexb;
      gap: 20px;
      .content {
        height: fit-content;
        max-width: 400px;
        margin-top: 60px;
        background-color: white;
        border-radius: 64px;
        padding: 20px 40px;
        margin-left: 5px;
        @include flexb(column, flex-start, center);

        .icon {
          width: 100px;
          height: 100px;
          margin-bottom: 10px;
          border-radius: 100%;
          margin-right: auto;
          object-fit: contain;
          box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
        }

        .text {
          .p {
            font-size: 1.2rem;
          }
        }
      }

      @include media("<=desktop") {
        flex-direction: column;

        .content {
          max-width: 500px;
          width: 100%;
          margin: 20px 0;
        }
      }

      @include media("<=370px") {
        .content {
          padding: 20px 20px;
          .icon {
            width: 70px;
            height: 70px;
          }

          .text {
            .p {
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}
