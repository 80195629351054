@mixin centerItems {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-5 {
  font-family: $font;
  margin: 0 auto;
  margin-top: 80px;

  .container {
    height: fit-content;
    margin: 0 auto;
    position: relative;
    padding-top: 40px;

    /* TABLET */
    @include media(">phone", "<=tablet") {
      width: 100%;
      margin: 0 auto;
      display: block;
      margin-left: 10px;
    }
    /* END TABLET */

    .content {
      width: 33.33%;
      margin-top: 45px;
      @include flexb;
      gap: 15px;

      .p {
        margin-bottom: 0;
      }

      .icon {
        width: 90px;
        height: 90px;
      }

      @include media("<=1200px") {
        .icon {
          width: 80px;
          height: 80px;
        }
      }

      /* TABLET */
      @include media(">phone", "<=desktop") {
        width: 400px;
        margin: 0 0;
        margin-top: 30px;

        .icon {
          width: 100px;
          height: 100px;

          .p {
            font-size: 18px;
          }

          img {
            width: 50px !important;
          }
        }
      }

      @include media("<=500px") {
        max-width: 100%;
        width: 100%;
        margin: 0 0;
        margin-top: 30px;
        justify-content: flex-start;

        .icon {
          width: 50px;
          height: 50px;
        }
      }
      /* END TABLET */
    }
  }
}
