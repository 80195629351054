.Header {
  background: #00beb0;
  display: block;
  border: 0;
  margin-top: 0;
  padding: 0;
  font: 12px "OpenSans", sans-serif !important;
  font-weight: 400;
  color: #494949;
  box-sizing: border-box;
  position: relative;
}
.Wrapper {
  width: 97%;
  margin: auto;
}
.Logo {
  font-size: 18px;
  font-weight: 300;
  color: #fff;
  text-transform: uppercase;
  line-height: 50px;
  outline: 0;
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}
.Logo span {
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}
.Nav {
  display: inline-block;
  vertical-align: top;
  margin-left: 1.8%;
}
.Nav_ul {
  list-style: none;
}
.Nav_li {
  display: inline-block;
}
.Text {
  padding: 5px 8px 5px 8px;
  background-color: transparent;
  border-radius: 3px;
  color: #fff !important;
  transition: all ease-in-out 0.2s;
  margin: 0 2px;
  line-height: 50px;
  font-weight: 900;
  outline: 0;
  text-decoration: none;
  font-style: inherit;
}
.Text:hover {
  color: #00a89b !important;
  cursor: pointer;
}
.active {
  padding: 5px 8px 5px 8px;
  background-color: #00a89b !important;
  color: #fff !important;
  border-radius: 3px;
}
.active:hover > li > .Text {
  color: #fff !important;
}
.Show_User_Params {
  padding: 8px 8px 0 20px;
  float: right;
  cursor: pointer;
  border-left: 1px solid #00a89b;
  height: 50px;
}
.profile_avatar {
  height: 34px;
  width: 34px;
  border-radius: 50% !important;
  background-color: #ffffff;
  display: inline-block;
  float: left;
  transition: all ease-in-out 0.2s;
  text-decoration: none;
  color: #00beb0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.UserName {
  margin: 10px 0px 0 10px;
  float: left;
  color: #fff;
}
.SubMenuUserParam {
  width: 139px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #c3c3c3;
  background-color: #ffffff;
  height: 53px;
  z-index: 99;
  position: absolute;
  top: 45px;
  right: 22px;
  display: none;
  padding: 8px;
}
.SubMenuUserDisplay {
  display: block;
}
/* .SubMenuUserParam::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  right: 26px;
  top: -5px;
  transform: rotate(45deg);
} */
.InfosList {
  align-items: center;
}
.ItemList {
  border-top: solid 1px #f5f5f5;
  height: 60px;
  width: 201px;
  font-family: OpenSans;
  font-weight: 600;
  color: #494949;
}
.LogOutItemList {
  border: solid 1px #f5f5f5;
  border-radius: 11%;
  height: 38px;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.LogOutItemList:hover {
  border: solid 1px #f0f0f0;
  border-radius: 10px;
  height: 38px;
  background-color: #f0f0f0;
}
.LogOutText {
  font-family: "OpenSans";
  font-weight: 600;
  color: #f84b4b;
  float: left;
  margin-right: 5px;
}
.LogOutIcon {
  float: right;
  margin-top: 3px;
}
.LogOutLogo {
  color: #f84b4b;
}
