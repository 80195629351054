@import "./AboutLandingPage/styles/breakpoints/breakpoints";
@import "./AboutLandingPage/styles/config";
@import "./AboutLandingPage/styles/HeaderLinks";
@import "./AboutLandingPage/styles/PageHeader";
@import "./AboutLandingPage/styles/section1";
@import "./AboutLandingPage/styles/section2";
@import "./AboutLandingPage/styles/section3";
@import "./AboutLandingPage/styles/section4";
@import "./AboutLandingPage/styles/section5";
@import "./AboutLandingPage/styles/section6";
@import "./AboutLandingPage/styles/section7";
@import "./AboutLandingPage/styles/section8";
@import "./AboutLandingPage/styles/section9";
@import "./AboutLandingPage/styles/buttons";
@import "./AboutLandingPage/styles/Article";
@import "./AboutLandingPage/styles/titles";
@import "./AboutLandingPage/styles/inputs";
@import "./AboutLandingPage/styles/header";
@import "./AboutLandingPage/styles/footer";

.shape {
  position: absolute;
  right: 0;
  top: 0;
  object-fit: contain;
  width: 50vw;
}