@mixin centerItems {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.groupInput {
  font-family: $font;
  label {
    font-size: 16px;
    font-family: $font;
  }
  .landing_page_input, .input {
    border: 0.2px solid #d0d0d0;
    padding: 5px 10px;
    border-radius: 100px;
    margin-top: 15px;
    background-color: #fafafa;
    height: auto !important;
    @include flexb(row, flex-start);

    &:focus-within {
      border: 0.2px solid $secondry-color;
    }

    .iconInput {
      width: 50px;
      height: 50px;
      border-radius: 100px;
      background-color: rgba($color: #efefef, $alpha: 1);
      @include centerItems();
    }
    .landing_page_input, input, select {
      height: 59px;
      font-size: 14px;
      border: none;
      outline: none;
      width: 70%;
      background-color: #fafafa;
      margin-left: 10px;
      font-family: $font;
    }
  }
  @include media("<=500px") {
    .landing_page_input {
      .iconInput {
        width: 45px;
        height: 45px;

        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
