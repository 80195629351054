.Post {
  width: 100%;
  display: block;
  position: relative;
  margin-left: 0.5%;
  margin-right: 0.5%;
  margin-bottom: 3%;
  background: #fff;
  box-shadow: 0 1px 3px 0 #bcbcbc;
  min-height: auto;
  padding: 10px;
  background: #fff;
  padding-bottom: 20px;
  font: 12px "OpenSans", sans-serif;

}
.User_avatar {
  display: inline-block !important;
  width: 52px !important;
  height: 54px !important;
  margin-right: 10px !important;
}
.Post_infos {
  display: inline-block;
  vertical-align: top;
  margin-top: 5px;
}
.User_name-doctor {
  font-family:  'OpenSans';
  color: #494949;
  font-size: 13px;
  font-weight: 700;
}
.Post_date {
  font-family:  'OpenSans';
  font-weight: 400;
  text-align: left;
  margin-top: 5px !important;
  color: #828282 !important;
  font-size: 12px !important;
}
.Post_type {
  display: inline-block;
  vertical-align: top;
  height: 28px;
  position: absolute;
  right: 0;
  top: 12px;
  border-radius: 4px 0 0 4px;
  background-color: #00beb0;
  font-family:  'OpenSans';
  font-size: 13px;
  text-align: center;
  display: inline-block;
  padding-left: 14px;
  padding-right: 14px;
  color: #fff;
  padding-top: 6px;
}
.Post_content {
  margin-top: 7px;
}
.Post_img {
  border-radius: 5px;
  margin-top: 10px;
  width: 100%;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  height: auto;
}
