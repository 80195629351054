@mixin centerItems {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
}

.btn {
  background: $primary-color;
  border-radius: 100px;
  max-width: 300px;
  color: $btn-font-color !important;
  font-weight: 400;
  font-size: 14px;
  height: 45px;
  width: 290px;
  font-family: $font;
  @include flexb(row, space-evenly);

  img {
      width: 15px !important;
  }

  @include media("<=1399px") {
      width: 250px;

      img {
          width: 12px !important;
          margin-left: 5px;
      }
  }

  @include media("<=350px") {
      max-width: 220px;
      width: 100%;
      font-size: 11px;

      img {
          width: 12px !important;
          margin-left: 5px;
      }
  }
}

.btn:hover {
  background: $primary-color-hover;
  cursor: pointer;
}

.btn-outline {
  max-width: 200px;
  background: white;
  border-radius: 34px;
  color: $primary-color !important;
  font-size: 14px;
  border: 1px solid $primary-color;
  margin-right: 5px;
  height: 45px;
  width: 130px;
  @include flexb();

  @include media("<=1200px") {
      width: 110px;
  }
}

.btn-outline:hover {
  background: #f5f5f5;
  cursor: pointer;
}
