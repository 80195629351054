.Menu_container {
  width: 24%;
  display: inline-block;
  position: relative;
  margin-left: 0.5%;
  margin-right: 0.5%;
  margin-bottom: 1%;
  font: 12px "OpenSans", sans-serif;

}
.Compte_infos {
  background: #fff;
  box-shadow: 0 1px 3px 0 #bcbcbc;
  padding: 10px;
  position: relative;
  min-height: 70px !important;
}
.Menu_container:hover {
  display: inline-block;
}
.Avatar_container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  background-color: #fbfbfb;
  position: absolute;
}
.Avatar {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background-color: #fbfbfb;
}
.User_name {
  font-family: "OpenSans";
  color: #1ebeb0;
  font-weight: 700;
  font-size: 14px;
  margin-top: 4px;
  margin-left: 19.3%;
}
.User_email {
  font-family: "OpenSans";
  font-weight: 400;
  font-size: 13px;
  margin-left: 19.3%;
}
.Update_btn {
  display: none;
  position: absolute;
  right: 5px;
  top: 6px;
  cursor: pointer;
}
.Update_icon {
  display: inline-block;
  width: 24px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: -229px -450px;
  margin-top: -3px;
  margin-right: -1px;
  float: right;
  margin-left: -18px;
  background-size: 334px;
}
.Waiting_prod_container {
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
}
.Waiting_prod_icon {
  display: inline-block;
  vertical-align: top;
  width: 35px;
  height: 31px;
  background-image: url(../../images/sprite_5.png);
  background-repeat: no-repeat;
  background-position: -186px -499px;
  margin-top: -6px;
  margin-right: 3px;
  margin-left: 0;
  background-size: 283px;
}
.WaitingProdText {
  display: inline-block;
  vertical-align: top;
  width: 75%;
}
.WaitingProdQuantity {
  display: inline-block;
  vertical-align: top;
  background-color: #d5d5d5;
  color: #858585;
  font-weight: 700;
  padding: 4px 12px 4px 12px;
  border-radius: 50px 50px 50px 50px;
  float: right;
}
.Links {
  margin-top: 10px;
}
.LinksTitle {
  font-family: "OpenSans";
  font-size: 14px;
  font-weight: 700;
}
.Links li {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.Links ul {
  width: 100%;
  /* margin-top: 20px; */
  list-style: none;
}
.Marketplace {
  display: inline-block;
  width: 35px;
  height: 31px;
  background-image: url(../../images/sprite_5.png);
  background-repeat: no-repeat;
  background-position: -186px -557px;
  margin-top: -6px;
  margin-right: 3px;
  float: left;
  margin-left: 0;
  background-size: 283px;
}
.Panel {
  display: inline-block;
  width: 35px;
  height: 35px;
  /* background-color: #FFCA42; */
  /* margin-right: 3px; */
  border-radius: 50%;
}
.Delivery {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #00c1b0;
  /* margin-right: 3px; */
  border-radius: 50%;
}
.Command {
  display: inline-block;
  width: 35px;
  height: 31px;
  background-image: url(../../images/sprite_5.png);
  background-repeat: no-repeat;
  background-position: -186px -467px;
  margin-top: -6px;
  margin-right: 3px;
  float: left;
  margin-left: 0;
  background-size: 283px;
}
.Item_link {
  font-family: "OpenSans";
  font-size: 13px;
  font-weight: 400;
  outline: 0;
  text-decoration: none;
  cursor: pointer;
}

.Item_link:hover {
  font-weight: 400;
  color: #1ebeb0;
}
.Item_link span {
  font-style: italic;
}
.Facture_home {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #8174b5;
  /* margin-right: 3px; */
  border-radius: 50%;
}

.EditBox_container {
  display: none;
}

.Compte_infos:hover > .EditBox_container {
  display: block;
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 20px;
  color: #d7d7d7;
  cursor: pointer;
}
