@mixin centerItems {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-7 {
  font-family: $font;
  position: relative;
  margin-top: 80px;
  margin: 0 auto;

  .container {
    height: auto;
    margin: 100px auto 0;
    position: relative;
    @include centerItems();

    .content {
      .h3 {
        color: $secondry-color;
      }

      p {
        font-weight: 300;
        strong {
          font-weight: 500;
        }
      }

      @include media("<=500px") {
        .h1 {
          font-size: 2.5rem;
          text-align: center;
        }

        p {
          text-align: center;
        }
      }

      .ImgContent {
        img {
          width: 95%;
        }
        @include media("<=991px") {
          margin-bottom: 50px;
        }
      }
    }
  }
}
