.SideMenu {
  width: 25%;
  margin-right: 1%;
  display: inline-table;
  font: 12px "OpenSans", sans-serif;
  height: 100%;
}
.Li {
  cursor: pointer;
  position: relative;
  background-color: #f6f6f6;
}
.Li_container {
  border-top: 1px solid transparent;
  padding: 9px !important;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background-color: #fff !important;
  border-top: 1px solid #d8d8d8;
}
.Li_container:hover {
  border-top: 1px solid transparent;
  padding: 9px !important;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background-color: #f6f6f6 !important;
  border-top: 1px solid #d8d8d8;
}
.Li_container_active {
  border-top: 1px solid transparent;
  padding: 9px !important;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background-color: #f6f6f6 !important;
  border-top: 1px solid #d8d8d8;
}
.Col_1_li {
  font-weight: 400 !important;
  font: 12px "OpenSans", sans-serif;
  display: inline-block;
  vertical-align: top;
  color: #0a8fad;
}
.Under_list {
  width: 100%;
  padding-bottom: 4px;
  background: #fff;
  display: none;
}
.Under_list_display {
  width: 100%;
  padding-bottom: 4px;
  background: #fff;
  display: block;
}
.Under_list_li_is_Active {
  display: block;
  padding: 2px 9px;
  color: #0a8fad;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  margin-bottom: 3px;
}
.Under_list_li {
  display: block;
  padding: 2px 9px;
  color: #494949;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  margin-bottom: 3px;
}
.Under_list_li_is_Active:hover {
  display: block;
  padding: 2px 9px;
  color: #494949;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  margin-bottom: 3px;
}