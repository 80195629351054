.footer-container {
  margin-top: 200px;
  font-family: $font;
}

.footer-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  height: fit-content;
  margin: 0 auto 0 auto;
  padding: 0 50px;
  font-family: $font;
}

.footer-card {
  height: 350px;
  padding: 10px;
  font-family: $font;
}

.footer-col1 {
  width: 30%;
  display: flex;
  flex-direction: column;
  font-family: $font;
}

.footer-col2 {
  width: 20%;
  font-family: $font;
}

.footer-col3 {
  width: 25%;
  font-family: $font;
}

.footer-col4 {
  width: 25%;
  font-family: $font;
}

.footer-card > img {
  margin-bottom: 20px;
  width: 170px;
}

.footer-card h2 {
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 20px;
  font-family: $font;
}

.footer-card > a {
  font-size: 18px;
  @include flexb(row, flex-start, center);
  gap: 10px;
  height: 35px;
  font-family: $font;

  span {
    color: $primary-color;
    font-size: 2rem;
    height: fit-content;
  }

  &:hover {
    color: $primary-color;
  }
}

.footer-contact {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  font-family: $font;

  a {
    font-size: 18px;

    &:hover {
      color: $primary-color;
    }
  }

  .sobrus-email-footer {
    img {
      top: 0;
      width: 20px;
    }
  }
}

.footer-contact > img {
  width: 25px;
  margin-right: 20px;
}

.copyrights-bg {
  background-color: #008e7c;
  font-family: $font;
}

.footer-copyrights {
  height: fit-content;
  max-width: 1400px;
  padding: 20px 55px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: $font;
  gap: 30px;

  .a {
    text-decoration: underline !important;
    color: white !important;
    font-size: 20px;
  }
}

.footer-copyrights > p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  font-family: $font;
}

.footer-copyrights > img {
  margin: 0 20px;
}

.footer-copyrights > p {
  margin-bottom: 0;
  font-size: 20px;
  color: white;
  font-family: $font;
}

@media only screen and (max-width: 1230px) {
  .footer-container {
    margin-top: 200px;
  }

  .footer-card {
    h2 {
      font-size: 1.5rem;
    }

    a {
      font-size: 15px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .footer-container {
    margin-top: 120px;
  }

  .footer-info {
    flex-direction: column;
    justify-content: center;
    margin-top: 200px;
  }

  .footer-card {
    max-width: 500px;
    width: 100%;
    height: 250px;
  }

  .footer-copyrights {
    padding: 20px;
  }

  .footer-copyrights > p {
    font-size: 16px;
    align-items: center;
    justify-content: flex-start;
  }

  .footer-copyrights .a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 500px) {
  .footer-info {
    padding: 0 4vw;
  }

  .footer-card > h2 {
    font-size: 20px;
  }

  .footer-card > p {
    font-size: 16px;
  }

  .footer-contact > p {
    font-size: 16px;
  }

  .footer-copyrights {
    flex-direction: column;
    align-items: flex-start;

    img {
      width: 20px;
    }
  }

  .footer-copyrights > p {
    font-size: 14px;
    width: 220px;
  }
}
