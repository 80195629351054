.table_th_orderable th {
  cursor: pointer;
  background-color: #fff;
  font: 12px "OpenSans", sans-serif;
}
.table_th_orderable th {
  cursor: pointer;
  background-color: #fff;
  font: 12px "OpenSans", sans-serif;
}
.Search_input_container {
  height: 38px;
  position: relative;
  font: 12px "OpenSans", sans-serif;

}
.Search_component_icon {
  position: absolute;
  height: 10px;
  width: 10px;
  top: 10px;
  right: 7%;
}
.Search_component_icon svg {
  width: 15px;
}
.table_th_orderable {
  background-color: #fff;
}
.BasicSearchComp_search_btn {
  width: 39px;
  height: 39px;
  outline: none;
  border: 0;
  background: #18b1d4;
  position: relative;
  cursor: pointer;
  padding-top: 1px;
  border-radius: 3px;
}
.BasicSearchComp {
  width: 15px;
  color: white;
}
.Search_component_icon i {
  width: 15px;
}
.entryDate_input_style {
  width: 100%;
  height: 100%;
  resize: none;
  margin: 0;
  outline: none;
  font-size: 14px;
  padding-left: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  border-radius: 6px;
  border: solid 1px #fff;
  cursor: text;
}
.entryDate_input_style:hover {
  width: 100%;
  height: 100%;
  resize: none;
  margin: 0;
  outline: none;
  font-size: 14px;
  padding-left: 10px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  border-radius: 6px;
  border: solid 1px #18b1d4;
  cursor: text;
}
input[type="date"] {
  font-family: "OpenSans";
  font-size: 14px;
  line-height: 0.94;
  letter-spacing: 0.22px;
  text-align: left;
  color: #284358;
  cursor: auto;
}