.ContactButton {
  position: fixed;
  z-index: 99;
  bottom: 2.5%;
  right: 29px;
  font: 12px "OpenSans", sans-serif;

}
.Pop_up_container {
  width: 313px;
  height: 400px;
  background-color: #fff;
  box-shadow: 0 2px 14px -2px #bcbcbc;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: hidden;
}
.HeaderContainer {
  width: 100%;
  height: 57px;
  background-color: #c557d4;
  padding-top: 16px;
}
.HeaderContainer p {
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
}
.ContentContainer {
  width: 100%;
  height: calc(100% - 57px);
  padding-top: 40px;
  text-align: center;
}
.CircleIcon {
  width: 120px;
  height: 120px;
  border-radius: 1200px;
  background: #fcf7fe;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  text-align: center;
}
.CircleIcon img {
  width: 105px;
  margin-top: 7px;
}
.ContentContainer p {
  color: #585858;
  font-size: 14px;
  font-weight: 700;
  margin-top: 17px;
}
.ContentContainer span {
  color: #00beb0;
  font-size: 18px;
  font-weight: 900;
  margin-top: 12px;
}
.ContactButton ul {
  list-style: none;
  display: inline-block;
  height: 44px;
  float: right;
}
.ContactButton li {
  display: inline-block;
  height: 44px;
}
.Contact_btn {
  display: inline-block;
  position: relative;
  width: 177px;
  height: 44px;
  border-radius: 50px 50px 50px 50px;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 #bcbcbc;
  transition: all ease-in-out 0.2s;
  background-color: #00beb0;
}
.Contact_btn_inshow {
  position: relative;
  width: 177px;
  height: 44px;
  border-radius: 50px 50px 50px 50px;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 #bcbcbc;
  transition: all ease-in-out 0.2s;
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.Icon_container {
  display: inline-block;
  vertical-align: top;
  width: 33px;
  height: 31px;
  background-color: #fff;
  border-radius: 330px;
  position: absolute;
  top: 13%;
  left: 5%;
  text-align: center;
  padding-top: 6px;
}
.Contact_icon {
  width: 33px;
  height: 31px;
  background-image: url(../../images/sprite_5.png);
  background-repeat: no-repeat;
  background-position: -453px -538px;
  top: 13%;
  left: 7%;
}
.Contact_btn_text {
  display: inline-block;
  vertical-align: top;
  font-weight: 700;
  font-family: "openSans";
  font-size: 14px;
  margin-top: 6px;
  margin-left: 42px;
  color: #fff;
}
.Contact_btn_text_inshow {
  display: inline-block;
  vertical-align: top;
  font-weight: 700;
  font-family: "openSans";
  font-size: 14px;
  color: rgb(80, 80, 80);
}
