.Bloc {
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: 97%;
  position: relative;
  margin: auto;
  margin-bottom: 60px;
  margin-top: 10px;
  font: 12px "OpenSans", sans-serif;

}
.sprite-bloc-search:hover,
.sprite-bloc-reload:hover,
.sprite-bloc-search:active,
.sprite-bloc-reload:active,
.sprite-bloc-star:hover,
.sprite-bloc-star:active {
  color: #0a8fad !important;
}

.sprite-bloc-star,
.sprite-bloc-search,
.sprite-bloc-reload,
.sprite-bloc-star {
  width: 17px;
  height: 17px;
  font-size: 17px;
  background: none !important;
}

.TH {
  cursor: pointer;
}
.THA {
  float: left !important;
}
.Search-Input {
  background-color: #fff;
}
.bloc_header h2 {
  float: left;
}
.table tbody tr:hover .Modifie_icon {
  background-color: #fff;
  color: #0a8fad;
  padding: 3px;
  border-radius: 50%;
}
.table tbody tr .Modifie_icon {
  padding: 3px;
  margin-left: 3px;
}
.OrderUp {
  background-image: url(../../images/sprite_3.png);
  background-repeat: no-repeat;
  display: inline-block;
  width: 8px;
  height: 5px;
  background-position: -213px -98px;
  position: absolute;
  right: 4px;
  top: 50%;
  margin-top: -4px;
}
.OrderDown {
  background-image: url(../../images/sprite_3.png);
  background-repeat: no-repeat;
  display: inline-block;
  width: 8px;
  background-position: -195px -98px;
  position: absolute;
  right: 5px;
  top: 50%;
  margin-top: 4px;
}

.up_and_down_icons {
  margin-left: 6px;
}
.TD {
  border-top: 1px solid #8d8d8d;
  font-weight: 400;
  padding: 6px 5px;
}
.TR {
  cursor: pointer;
}
.TR:hover {
  color: #fff !important;
}
.search_icon {
  position: absolute;
  height: 10px;
  width: 10px;
  top: 10px;
  right: 10px;
}
.Search_input_container {
  height: 38px;
  position: relative;
}
.fa-w-16 {
  width: 15px;
}
.Search_btn {
  width: 28px;
  height: 27px;
  outline: none;
  border: 0;
  background: #00beb0;
  position: relative;
  cursor: pointer;
  padding-top: 5px;
  border-radius: 3px;
}
.FilterCulomn {
  border-bottom: 2px solid #18b1d4;
}
.tag.yallow_non_payed {
  border: 1px solid #f4ac2f;
  color: #494949;
  padding: 0px 10px 0 25px;
}
.tag.yallow_non_payed:after {
  background: #f4ac2f;
}
.yallow_non_payed {
  background-color: #efefef;
  color: #494949;
  border: 2px solid #f4ac2f;
  font: 12px "OpenSans", sans-serif;
  font-weight: 600;
  border-radius: 3px;
}
.tag.red_button {
  border: 1px solid #dd5348;
  color: #494949;
  padding: 0px 10px 0 25px;
}
.tag.red_button:after {
  background: #dd5348;
}
.red_button {
  background-color: #efefef;
  color: #494949;
  border: 2px solid #dd5348;
  font: 12px "OpenSans", sans-serif;
  font-weight: 600;
  border-radius: 3px;
}
.tag.blue_button {
  border: 1px solid #18b1d4;
  color: #494949;
  padding: 0px 10px 0 25px;
}
.blue_button {
  background-color: #efefef;
  color: #494949;
  border: 2px solid #18b1d4;
  font: 12px "OpenSans", sans-serif;
  font-weight: 600;
  border-radius: 3px;
}
.tag.blue_button:after {
  background: #18b1d4;
}

.tag.rouse_button {
  border: 1px solid #ef3b68;
  color: #494949;
  padding: 0px 10px 0 25px;
}
.rouse_button {
  background-color: #efefef;
  color: #494949;
  border: 2px solid #ef3b68;
  font: 12px "OpenSans", sans-serif;
  font-weight: 600;
  border-radius: 3px;
}
.tag.rouse_button:after {
  background: #ef3b68;
}

.tag.green_button {
  border: 1px solid #49bbac;
  color: #494949;
  padding: 0px 10px 0 25px;
}
.tag.green_button:after {
  background: #49bbac;
}
.green_button {
  background-color: #efefef;
  color: #494949;
  border: 2px solid #49bbac;
  font: 12px "OpenSans", sans-serif;
  font-weight: 600;
  border-radius: 3px;
}
.tag.green_button {
  border: 1px solid #49bbac;
  color: #494949;
  padding: 0px 10px 0 25px;
}
.tag.green_button:after {
  background: #49bbac;
}
.tag.move_button:after {
  background: #8174b5;
}
.move_button {
  background-color: #efefef;
  color: #494949;
  /* border-radius: 6px; */
  border: 2px solid #8174b5;
  font: 12px "OpenSans", sans-serif;
  font-weight: 600;
  border-radius: 3px;
  position: relative;
}
.tag.move_button {
  border: 1px solid #8174b5;
  color: #494949;
  padding: 0px 10px 0 25px;
}

.livButton {
  background-color: #91a8ae;
  border: 1px solid #91a8ae;
  border-radius: 3px;
  width: 145px;
  height: 30px;
  padding: 6px;
  color: #fff;
  text-align: center;
  font-family: "openSans-Bold";
}
.nouButton {
  background-color: #f2a628;
  border: 1px solid #f2a628;
  border-radius: 3px;
  width: 145px;
  height: 30px;
  padding: 6px;
  color: #fff;
  text-align: center;
  font-family: "openSans-Bold";
}
.encButton {
  background-color: #3d7ea6;
  border: 1px solid #3d7ea6;
  border-radius: 3px;
  width: 145px;
  height: 30px;
  padding: 6px;
  color: #fff;
  text-align: center;
  font-family: "openSans-Bold";
}

.preButton {
  background-color: #02c288;
  border: 1px solid #02c288;
  border-radius: 3px;
  width: 145px;
  height: 30px;
  padding: 6px;
  color: #fff;
  text-align: center;
  font-family: "openSans-Bold";
}

.annButton {
  background-color: #f52951;
  border: 1px solid #f52951;
  border-radius: 3px;
  width: 145px;
  height: 30px;
  padding: 6px;
  color: #fff;
  text-align: center;
  font-family: "openSans-Bold";
}
