.ParametresFacture_input {
  margin: 2% 0%;
  font: 12px "OpenSans", sans-serif;
}
.PharmacyServiceEdit {
  min-width: 960px;
}

/* The container */
.PharmacyServiceEdit_container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: 2px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font: 12px "OpenSans", sans-serif;
}

/* Hide the browser's default checkbox */
.PharmacyServiceEdit_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 20px !important;
  width: 20px !important;
  background-color: #eee !important;
}

/* On mouse-over, add a grey background color */
.PharmacyServiceEdit_container:hover input ~ .checkmark {
  background-color: #ccc !important;
}

/* When the checkbox is checked, add a blue background */
.PharmacyServiceEdit_container input:checked ~ .checkmark {
  background-color: #00beb0 !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "" !important;
  position: absolute !important;
  display: none !important;
}

/* Show the checkmark when checked */
.PharmacyServiceEdit_container input:checked ~ .checkmark:after {
  display: block !important;
}

/* Style the checkmark/indicator */
.PharmacyServiceEdit_container .checkmark:after {
  left: 8px !important;
  top: 4px !important;
  width: 5px !important;
  height: 11px !important;
  border: solid white !important;
  border-width: 0 3px 3px 0 !important;
  transform: rotate(45deg) !important;
}
.custom-control-label:before {
  width: 6px !important;
  height: 6px !important;
  background-color: #dedede !important;
  border: none !important;
  box-shadow: none !important;
}
.CheckBoxLabels {
  font-family: "openSans" !important;
  padding-top: 3px !important;
  margin-right: 30px !important;
  color: #465f7e !important;
  font-weight: 600 !important;
}
