.Profile {
  box-shadow: 0 1px 3px 0 #bcbcbc;
}
.OrdonnancePage_container{
  min-width: 960px;
  font: 12px "OpenSans", sans-serif;
}
.OrdonnancePage {
  margin: 1% 1.5%;
  display: flex;
  justify-content: space-between;
}
.ParametresGrid {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  font: 12px "OpenSans", sans-serif;
}
.ParametresGridDetail {
  width: 49%;
  font: 12px "OpenSans", sans-serif;

}
.table {
  width: 100%;
  background: #fff;
  font: 12px "OpenSans", sans-serif;

}
.TR {
  cursor: pointer;
}
.TD {
  border-top: 1px solid #8d8d8d;
  font-weight: 400;
  padding: 6px 5px;
}
.InfoCard {
  box-shadow: 0 1px 3px 0 #bcbcbc;
  width: "29%";
  height: "100%";
  margin-top: "0px";
  font: 12px "OpenSans", sans-serif;

}
.NouvelleColor {
  background: #f4ac2f;
  border: 1px solid #f4ac2f;
  margin: auto auto 10px;
  padding: 10px;
  border-radius: 0 !important;
  box-shadow: 0 1px 3px 0 #bcbcbc;
}
.EncourColor {
  background: #3d7ea6;
  border: 1px solid #3d7ea6;
  margin: auto auto 10px;
  padding: 10px;
  border-radius: 0 !important;
  box-shadow: 0 1px 3px 0 #bcbcbc;
}
.PreColor {
  background: #02c288;
  border: 1px solid #02c288;
  margin: auto auto 10px;
  padding: 10px;
  border-radius: 0 !important;
  box-shadow: 0 1px 3px 0 #bcbcbc;
}
.AnnuColor {
  background: #f52951;
  border: 1px solid #f52951;
  margin: auto auto 10px;
  padding: 10px;
  border-radius: 0 !important;
  box-shadow: 0 1px 3px 0 #bcbcbc;
}
.LivrColor {
  background: #91a8ae;
  border: 1px solid #91a8ae;
  margin: auto auto 10px;
  padding: 10px;
  border-radius: 0 !important;
  box-shadow: 0 1px 3px 0 #bcbcbc;
}
.Color_span {
  font-family: "openSans";
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  margin-top: 4px;
}
.btn_green {
  display: inline-block;
  padding: 0 22px;
  height: 25px;
  color: #fff;
  text-align: center;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
  background: #ffa902;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #ffa902;
}

.btn_green:hover {
  display: inline-block;
  padding: 0 22px;
  height: 25px;
  color: #fff;
  text-align: center;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
  background: #ffa902;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #ffa902;
}

.btn_green:active {
  display: inline-block;
  padding: 0 22px;
  height: 25px;
  color: #fff;
  text-align: center;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
  background: #ffa902;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  border: 1px solid #ffa902;
}

.btn_green.large {
  width: 100%;
}
.css-1cm5myo {
  height: auto;
  max-height: 100vh !important;
}
