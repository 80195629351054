@mixin centerItems {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-6 {
  font-family: $font;
  margin: 100px auto 0;
  background-color: $secondry-color-opacity;
  padding-top: 50px;
  @include media("<=desktop") {
    margin-top: 50px;
  }

  .container {
    height: fit-content;
    margin: 0 auto;
    @include centerItems();
    @include media("<=991px") {
      margin-top: 50px;
      padding: 30px 0px;
      height: auto;
      text-align: center;
    }
    .content {
      z-index: 10;
      .btn-customize {
        background-color: $secondry-color;

        @include media("<=desktop") {
          max-width: 300px;
          width: 100%;
          margin: 0 auto;
        }

        @include media("<=phone") {
          max-width: 250px;
          width: 100%;
        }
      }

      .ImgContent {
        @include flexb;
        img {
          max-width: 800px;
          width: 100%;
          margin: auto;

          @include media("<=desktop") {
            margin-top: 50px;
          }
        }
      }
    }
  }
}
